import {
  createStore as createReduxStore,
  combineReducers,
  applyMiddleware,
  compose
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import configureStoreMock from 'redux-mock-store';
import { useApiWsCalls } from '@src/utils/helpers/wsCalls';
import logoutReducer from './logoutReducer';

/* eslint-disable no-underscore-dangle, no-undef */
const DEVELOPMENT = process.env.NODE_ENV === 'development';
const HAS_WINDOW = typeof window === 'object';
const REDUX_DEVTOOLS = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
/* eslint-enable */

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
    savePreviousLocations: 5
  });

// Enable redux-devtools when not in production mode
const composeEnhancers =
  DEVELOPMENT && HAS_WINDOW && REDUX_DEVTOOLS ? REDUX_DEVTOOLS({}) : compose;

const [wsCalls, setWsCalls] = useApiWsCalls();

const enhancer = composeEnhancers(
  applyMiddleware(thunkMiddleware.withExtraArgument(wsCalls)),
  applyMiddleware(routerMiddleware)
);

const configureStore = (externalReducers = {}) => {
  const appReducer = combineReducers({
    router: routerReducer,
    ...externalReducers
  });

  const rootReducer = (state, action) =>
    appReducer(logoutReducer(state, action), action);

  return createReduxStore(rootReducer, enhancer);
};

export const history = store => createReduxHistory(store);

export const configureMockStore = () =>
  configureStoreMock([thunkMiddleware.withExtraArgument(wsCalls)]);

export const useWsCalls = () => [wsCalls, setWsCalls];

export default configureStore;
