export const parseUserAgent = agent => {
  const appVersionRegex = 'v[ei]ktklubbapp.*?/[0-9]+[.0-9]*[-0-9]*';
  const appVersion = agent.match(appVersionRegex);

  const platformiOS = agent.match('iPhone|iPad') ? 'iOS' : null;
  const platformAndroid = agent.match('Android') ? 'Android' : null;
  const platformDesktop = 'Desktop';

  const productiOSApp =
    agent.match('iPhone|iPad') && appVersion ? 'iOSApp' : null;
  const productAndroidApp =
    agent.match('Android') && appVersion ? 'AndroidApp' : null;
  const productWeb = 'Web';

  const isPrerender = agent.includes('prerender');
  const hasAppComponent = agent.includes('_app_');

  return {
    isApp: !!productiOSApp || !!productAndroidApp,
    productVersion: appVersion ? appVersion[0].split('/')[1] : null,
    platform: platformiOS || platformAndroid || platformDesktop,
    productType: productiOSApp || productAndroidApp || productWeb,
    isPrerender,
    disablePrivacySettings:
      (!!productiOSApp || !!productAndroidApp) && !hasAppComponent
  };
};

export const { isApp } = parseUserAgent(window.navigator.userAgent);
export const { isPrerender } = parseUserAgent(window.navigator.userAgent);
export const { disablePrivacySettings } = parseUserAgent(
  window.navigator.userAgent
);
