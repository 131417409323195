"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unloadScript = exports.loadScript = void 0;
const loadScript = (url, id) => new Promise((resolve, reject) => {
    if (document.querySelector(`#${id}`)) {
        resolve();
    }
    else {
        const script = document.createElement('script');
        script.src = url;
        script.id = id;
        script.onerror = err => reject(err);
        script.onload = () => resolve();
        script.async = true;
        document.head.appendChild(script);
    }
});
exports.loadScript = loadScript;
const unloadScript = (id) => new Promise(resolve => {
    const script = document.querySelector(`#${id}`);
    if (script) {
        document.head.removeChild(script);
    }
    resolve();
});
exports.unloadScript = unloadScript;
exports.default = { loadScript: exports.loadScript, unloadScript: exports.unloadScript };
