import localize from '@src/i18n';

export const units = {
  GRAM: 'GRAM',
  HECTOGRAM: 'HECTOGRAM',
  KILOGRAM: 'KILOGRAM',
  MILLILITER: 'MILLILITER',
  SPICE_MEASURE: 'SPICE_MEASURE',
  TEASPOON: 'TEASPOON',
  TABLESPOON: 'TABLESPOON',
  CENTILITER: 'CENTILITER',
  DECILITER: 'DECILITER',
  LITER: 'LITER'
};

export const unitCodes = [
  'g',
  'hg',
  'kg',
  'ml',
  'krm',
  'tsk',
  'msk',
  'cl',
  'dl',
  'l'
];

export const unitType = {
  WEIGHT: 0,
  VOLUME: 1,
  CUSTOM: 2
};

export const unitTypeFromUnit = unit => {
  switch (unit) {
    case units.MILLILITER:
    case units.SPICE_MEASURE:
    case units.TEASPOON:
    case units.TABLESPOON:
    case units.CENTILITER:
    case units.DECILITER:
    case units.LITER:
      return unitType.VOLUME;
    case units.GRAM:
    case units.HECTOGRAM:
    case units.KILOGRAM:
      return unitType.WEIGHT;
    default:
      return unitType.CUSTOM;
  }
};

export const localizedUnit = unit => {
  switch (unit) {
    case units.MILLILITER:
      return localize('generic_unit_ml');
    case units.SPICE_MEASURE:
      return localize('generic_unit_krm');
    case units.TEASPOON:
      return localize('generic_unit_tsk');
    case units.TABLESPOON:
      return localize('generic_unit_msk');
    case units.CENTILITER:
      return localize('generic_unit_cl');
    case units.DECILITER:
      return localize('generic_unit_dl');
    case units.LITER:
      return localize('generic_unit_l');
    case units.GRAM:
      return localize('generic_unit_g');
    case units.HECTOGRAM:
      return localize('generic_unit_hg');
    case units.KILOGRAM:
      return localize('generic_unit_kg');
    default:
      return undefined;
  }
};

export const localizedUnitFromUnitCode = unit =>
  localize(`generic_unit_${unit}`) || localize(`generic_${unit}`) || '';

export const unitCodeFromLocalizedUnit = localizedUnitName => {
  const foundUnitCodes = unitCodes.filter(
    code => localize(`generic_unit_${code}`) === localizedUnitName
  );
  return foundUnitCodes ? foundUnitCodes[0] : null;
};

export const unitCodeFromUnit = unitName => {
  const foundUnitCodes = unitCodes.filter(code => code === unitName);
  return foundUnitCodes ? foundUnitCodes[0] : null;
};

export const unitFromLocalizedUnit = unitText =>
  Object.keys(units).filter(key => localizedUnit(key) === unitText)[0];

export const unitFactors = {
  ml: 1,
  krm: 1,
  tsk: 5,
  msk: 15,
  cl: 10,
  dl: 100,
  l: 1000
};

export const ceilByStep = (value, step = 1) => {
  const inv = 1 / step;
  return Math.ceil(value * inv) / inv;
};

export const floorByStep = (value, step = 1) => {
  const inv = 1 / step;
  return Math.floor(value * inv) / inv;
};

export const getGramsPerCustomUnit = (foodstuffUnits, unitName) =>
  foodstuffUnits.find(unit => unit.name === unitName)[0]?.gramsPerCustomUnit ||
  null;

export const getGramsPerCustomUnitForm = (customUnits, unitName) =>
  unitName.includes('CUSTOM')
    ? customUnits[unitName.split('-')[1]]?.gramsPerUnit
    : null;

export default {
  units,
  unitFactors,
  unitType,
  unitTypeFromUnit,
  localizedUnit,
  localizedUnitFromUnitCode,
  unitFromLocalizedUnit
};
