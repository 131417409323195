import { getActiveMealType } from '@src/utils/helpers/date';
import { DEFAULT_PRODUCT } from '@src/utils/helpers/isWellobe';

const mealTypeTabIndexes = {
  wellobe: {
    breakfast: 0,
    lunch: 1,
    dinner: 2,
    snack: 3
  },
  vektklubb: {
    breakfast: 0,
    lunch: 1,
    snack: 2,
    dinner: 3,
    supper: 4
  }
};

const getTabIndex = (mealType, product = DEFAULT_PRODUCT) => {
  if (mealType === 'supper' && product === DEFAULT_PRODUCT)
    return mealTypeTabIndexes[product].snack;
  return mealTypeTabIndexes[product][mealType];
};

const getTabIndexForTimeOfDay = (product = DEFAULT_PRODUCT, selectedTime) =>
  getTabIndex(getActiveMealType(selectedTime), product);

const fromIndex = (activeTabIndex, product = DEFAULT_PRODUCT) => {
  const mealTypeIndexes = mealTypeTabIndexes[product];

  return Object.keys(mealTypeIndexes).filter(
    item => mealTypeIndexes[item] === activeTabIndex
  )[0];
};

const getMealTypeItems = (product = DEFAULT_PRODUCT) => {
  const mealTypeIndexes = mealTypeTabIndexes[product];
  return Object.keys(mealTypeIndexes);
};

const isMealType = (mealType, product) => getTabIndex(mealType, product) > -1;

const getMealTypeNameForIndex = (product, index) =>
  getMealTypeItems(product)[index];

export default {
  getTabIndex,
  getTabIndexForTimeOfDay,
  fromIndex,
  isMealType,
  getMealTypeItems,
  getMealTypeNameForIndex
};
