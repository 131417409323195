import moment from 'moment';
import find from 'lodash/find';
import time from '@src/utils/constants/time';

const getMonday = selectedDay => moment(selectedDay).startOf('isoWeek');

export const getStartOfWeek = (selectedDay, daysBeforeSelected = 0) =>
  moment(getMonday(selectedDay))
    .subtract(daysBeforeSelected, 'day')
    .format('YYYYMMDD');

export const getEndOfWeek = selectedDay =>
  moment(getMonday(selectedDay)).add(6, 'day').format('YYYYMMDD');

export const getActiveMealType = selectedTime => {
  const format = 'HH:mm:ss';
  const formattedTime = selectedTime
    ? moment(selectedTime, format)
    : moment().format(format);
  const now = moment(formattedTime, format);

  const breakfastStart = moment('02:00:00', format);
  const lunchStart = moment('10:00:00', format);
  const firstSnackStart = moment('14:00:00', format);
  const dinnerStart = moment('16:00:00', format);
  const secondSnackStart = moment('22:00:00', format);

  if (now.isSameOrAfter(breakfastStart) && now.isBefore(lunchStart)) {
    return 'breakfast';
  }
  if (now.isSameOrAfter(lunchStart) && now.isBefore(firstSnackStart)) {
    return 'lunch';
  }
  if (now.isSameOrAfter(firstSnackStart) && now.isBefore(dinnerStart)) {
    return 'snack';
  }
  if (now.isSameOrAfter(dinnerStart) && now.isBefore(secondSnackStart)) {
    return 'dinner';
  }

  return 'supper';
};

export const getSemanticDay = (today, selectedDay, locale) => {
  if (!locale || !SEMANTIC_DAYS[locale]) {
    throw Error('No locale specified');
  }

  const semanticDays = SEMANTIC_DAYS[locale];
  const formattedSelectedDay = moment(selectedDay);

  switch (moment(today).diff(formattedSelectedDay, 'd')) {
    case 0:
      return semanticDays.today;
    case -1:
      return semanticDays.tomorrow;
    case -2:
      return semanticDays.twoDaysFromNow;
    case 1:
      return semanticDays.yesterday;
    case 2:
      return semanticDays.twoDaysAgo;
    default:
      return formattedSelectedDay.format('D MMM YYYY');
  }
};

const SEMANTIC_DAYS = {
  sv: {
    today: 'idag',
    tomorrow: 'imorgon',
    twoDaysFromNow: 'i övermorgon',
    yesterday: 'igår',
    twoDaysAgo: 'i förrgår'
  },
  nb: {
    today: 'i dag',
    tomorrow: 'i morgen',
    twoDaysFromNow: 'i overmorgen',
    yesterday: 'i går',
    twoDaysAgo: 'i forgårs'
  }
};

const selected = moment();
export const getKcalForSelectedDay = (
  kcalsArray,
  day = selected.format('YYYYMMDD')
) => {
  const found = find(kcalsArray, { day });
  return found ? Math.round(found.value) : 0;
};

export const getGreetingType = hour => {
  if (hour === null || hour === undefined) {
    return '';
  }

  const findKey = (found, key) => (key <= hour ? key : found);

  const key = Object.keys(GREETING_TYPES).reduce(findKey, 0);

  return GREETING_TYPES[key];
};

const GREETING_TYPES = {
  0: time.EVENING,
  5: time.MORNING,
  10: time.FORENOON,
  12: time.MIDDAY,
  14: time.AFTERNOON,
  18: time.EVENING
};
