import { errorTypes } from '@ducks/error';

// If logout action, reset to intial state by passing
// in state as undefined for relevant reducers,
// See http://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/
const logoutReducer = (state, action) => {
  if (action.type === errorTypes.LOGOUT_USER) {
    return undefined;
  }
  return state;
};

export default logoutReducer;
