export const BmiFirstStepDL = BMIValue => {
  if (window.dataLayer) {
    dataLayer.push({
      formStatus: 'Applied BMI',
      position: 'middle',
      BMIValue,
      formName: 'BMI form',
      event: 'BMIform'
    });
  }
};

export const BmiSecondStepDL = () => {
  if (window.dataLayer) {
    dataLayer.push({
      formStatus: 'CTA Button Click',
      position: 'middle',
      formName: 'BMI form',
      event: 'BMIform'
    });
  }
};

export const CampaignBarDL = () => {
  if (window.dataLayer) {
    dataLayer.push({
      event: 'CampaignButtonClick',
      button_type: 'Campaign Button'
    });
  }
};

export default (virtualPageURL, virtualPageTitle) => {
  if (window.dataLayer) {
    dataLayer.push({
      event: 'virtualPageView',
      virtualPageURL,
      virtualPageTitle
    });
  }
};
