export default {
  KCAL_PER_GRAM_FAT: 9,
  KCAL_PER_GRAM_CARBS: 4,
  KCAL_PER_GRAM_PROTEIN: 4,
  MIN_RECOMMENDED_FAT_PERCENT: 0.25,
  MAX_RECOMMENDED_FAT_PERCENT: 0.4,
  MIN_RECOMMENDED_CARBS_PERCENT: 0.45,
  MAX_RECOMMENDED_CARBS_PERCENT: 0.6,
  MIN_RECOMMENDED_PROTEIN_PERCENT: 0.1,
  MAX_RECOMMENDED_PROTEIN_PERCENT: 0.2,
  SHOW_MORE_THRESHOLD: 3
};
