import capitalize from 'lodash/capitalize';
import get from 'lodash/get';

function kcalsPerPortion(ingredients, portions = 1) {
  const kcalSumInRecipeBase = {
    protein: 0,
    fat: 0,
    carbs: 0,
    alcohol: 0
  };

  const kcalSumInRecipe = Object.keys(kcalSumInRecipeBase).reduce(
    (prev, currType) => ({
      ...prev,
      [currType]: (ingredients || []).reduce(
        (sum, currentIng) => getKcal(currentIng, currType) + sum,
        0
      )
    }),
    kcalSumInRecipeBase
  );

  return Object.keys(kcalSumInRecipe).reduce(
    (acc, currType) => ({
      ...acc,
      [currType]: kcalSumInRecipe[currType] / portions
    }),
    {}
  );
}

function getKcal(ingredient, type) {
  const kcalPerIngredient = ingredient.amount * ingredient.kcalPerUnit;
  return (
    (kcalPerIngredient *
      get(
        ingredient,
        ['energyDistribution', `energyPercentageFrom${capitalize(type)}`],
        0
      )) /
    100
  );
}

function getPercentsPerPortion(ingredients, portions = 1) {
  const kcalsPerPortionCurr = kcalsPerPortion(ingredients, portions);
  const sum = Object.keys(kcalsPerPortionCurr).reduce(
    (acc, currType) => acc + kcalsPerPortionCurr[currType],
    0.0
  );
  const percentsPerPortion = Object.keys(kcalsPerPortionCurr).reduce(
    (acc, currType) => ({
      ...acc,
      [currType]: (kcalsPerPortionCurr[currType] / sum) * 100
    }),
    {}
  );
  return percentsPerPortion;
}

function getKcalsPerPortionSum(ingredients, portions = 1) {
  const kcalsPP = kcalsPerPortion(ingredients, portions);
  return Object.keys(kcalsPP).reduce((acc, curr) => acc + kcalsPP[curr], 0);
}

export const getMenuCaloriesRange = kcalrecommended => {
  if (kcalrecommended >= 1300 && kcalrecommended <= 1700) return '1300-1700';
  if (kcalrecommended >= 1701 && kcalrecommended <= 2100) return '1700-2100';
  if (kcalrecommended >= 2101) return '2100-2400';
  return '';
};

export { getPercentsPerPortion, getKcalsPerPortionSum, kcalsPerPortion };
