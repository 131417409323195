export default {
  LOG_DRAWER_MODAL_ID: 'LOG_DRAWER_MODAL_ID',
  LOG_DRAWER_RECIPE_MODAL_ID: 'LOG_DRAWER_RECIPE_MODAL_ID',
  RECIPE_CATEGORIES_MODAL_ID: 'RECIPE_CATEGORIES_MODAL_ID',
  ADD_RECIPE_FOODSTUFF_MODAL_ID: 'ADD_RECIPE_FOODSTUFF_MODAL_ID',
  LOG_FOODSTUFF_MODAL_ID: 'LOG_FOODSTUFF_MODAL_ID',
  LOG_RECIPE_MODAL_ID: 'LOG_RECIPE_MODAL_ID',
  LOG_EXERCISE_MODAL_ID: 'LOG_EXERCISE_MODAL_ID',
  EDIT_FAST_DAYS_MODAL_ID: 'EDIT_FAST_DAYS_MODAL_ID',
  EDIT_KCAL_MODAL_ID: 'EDIT_KCAL_MODAL_ID',
  LOG_CONFIRMATION_MODAL: 'LOG_CONFIRMATION_MODAL',
  CONFIRMATION_MODAL: 'CONFIRMATION_MODAL',
  QUICK_LOG_MODAL: 'QUICK_LOG_MODAL',
  LOG_WEIGHT_MODAL: 'LOG_WEIGHT_MODAL',
  LOG_WAIST_MODAL: 'LOG_WAIST_MODAL',
  NOTES_MODAL: 'NOTES_MODAL',
  ALERT_MODAL: 'ALERT_MODAL',
  SHARE_RECIPE_MODAL: 'SHARE_RECIPE_MODAL',
  MERGE_CONFIRMATION_MODAL: 'MERGE_CONFIRMATION_MODAL',
  HEALTH_DATA_CONSENT_MODAL: 'HEALTH_DATA_CONSENT_MODAL',
  SWITCH_MEAL_MODAL: 'SWITCH_MEAL_MODAL',
  EDIT_INGREDIENT_GROUP_MODAL: 'EDIT_INGREDIENT_GROUP_MODAL',
  EDIT_INSTRUCTION_HEADLINE_MODAL: 'EDIT_INSTRUCTION_HEADLINE_MODAL',
  RECIPE_FILTERS_DRAWER_MODAL: ' RECIPE_FILTERS_DRAWER_MODAL'
};
