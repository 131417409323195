export const MAX_SMALL_MOBILE_WIDTH = 480;
export const MAX_MOBILE_WIDTH = 767;
export const MAX_TABLET_WIDTH = 991;
export const MIN_DESKTOP_WIDTH = MAX_TABLET_WIDTH + 1;

export const VERYSMALL_MEDIA = 'screen and (max-width: 480px)';
export const SMALL_MEDIA = 'screen and (max-width: 767px)';
export const MEDIUM_MEDIA =
  'screen and (max-width: 991px) and (min-width: 768px)';
export const LARGE_MEDIA = 'screen and (min-width: 992px)';
