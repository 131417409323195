import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import config from '../../config';

const getMoment = () => {
  const isProduction = config.schibstedAccount.env !== 'PRE';
  if (isProduction) {
    return moment();
  }
  const urlParams = new URLSearchParams(window.location.search);
  const forcedCampaignDate = urlParams.get('showCampaignAtDate');
  const forcedCampaignMoment = forcedCampaignDate && moment(forcedCampaignDate);
  return forcedCampaignMoment || moment();
};

export const addABVariantToURLParam = (url, variantName, paramName) => {
  const baseUrl = new URL(url);
  const { searchParams } = baseUrl;
  const originalUtmTerm = searchParams.get(paramName);
  searchParams.set(
    paramName,
    [originalUtmTerm, variantName].filter(x => !isEmpty(x)).join('+')
  );
  baseUrl.search = searchParams.toString();
  return baseUrl.toString();
};

export const overrideURLParam = (url, newParamValue, paramName) => {
  const baseUrl = new URL(url);
  const { searchParams } = baseUrl;
  searchParams.set(paramName, newParamValue);
  baseUrl.search = searchParams.toString();
  return baseUrl.toString();
};

export default getMoment;
