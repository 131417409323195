const dependencies = {};

function set(key, dependency) {
  dependencies[key] = dependency;
}

function get(key) {
  return dependencies[key];
}

export const keys = {
  SCHIBSTED_ACCOUNT: 'schibstedAccount',
  START_PROGRAM_REDIRECT: 'startProgramRedirect',
  PROGRAM_CONFIRMATION_REDIRECT: 'programConfirmationRedirect',
  HEALTH_CONSENT_REDIRECT: 'healthConsentRedirect',
  LINKS_LOGBOOK: 'links:logbook',
  LINKS_PROGRAM: 'links:program',
  LINKS_PROGRAM_START: 'links:programStart',
  DATE_FNS_KEY: 'dateFnsKey',
  DATE_FNS_LANG: 'dateFnsLang',
  ALLOW_RECIPE_EDIT: 'allowRecipeEdit',
  I18N: 'i18n',
  API_URL: 'apiUrl',
  CONSENT_API_URL: 'consentApiUrl',
  VERSION: 'version',
  PRODUCT_ID: 'product_id',
  TRACKING_ENABLED: 'tracking_enabled',
  PULSE_CONFIG: 'pulse_config'
};

export default {
  set,
  get,
  keys
};
