const status = {
  idle: 'idle',
  pending: 'pending',
  fulfilled: 'fulfilled',
  rejected: 'rejected'
};
export const isRequestIdle = currentStatus => currentStatus === status.idle;
export const isRequestPending = currentStatus =>
  currentStatus === status.pending;
export const isRequestFulfilled = currentStatus =>
  currentStatus === status.fulfilled;
export const isRequestRejected = currentStatus =>
  currentStatus === status.rejected;

export default status;
