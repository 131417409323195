const CREDENTIALS = 'include';
export const VG_CREDENTIALS = 'same-origin';
const HEADERS = {
  'Content-Type': 'application/json'
};

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
};

const parseJson = response => response.json();

const handleError = (error, parseRejected) => {
  if (error && error.response && parseRejected) {
    return parseJson(error.response);
  }
  return Promise.reject(error);
};

// Some endpoints might include response data, parse this to json and add
// it to the error object.
const handleRejected = (error, rejected, parseRejected) =>
  Promise.resolve(handleError(error, parseRejected))
    .then(response => rejected({ ...error, parsedResponse: response }))
    .catch(err => rejected(err));

const baseRequest = (
  method,
  fulfilled,
  rejected,
  url,
  payload,
  parseFulfilled = true,
  parseRejected = true,
  headers = HEADERS,
  credentials = CREDENTIALS,
  isJSON = true
) =>
  fetch(url, {
    method,
    headers,
    credentials,
    body: isJSON ? payload && JSON.stringify(payload) : payload
  })
    .then(checkStatus)
    .then(parseFulfilled && parseJson)
    .then(fulfilled)
    .catch(error => handleRejected(error, rejected, parseRejected));

export const getRequest = (
  fulfilled,
  rejected,
  url,
  parseFulfilled = true,
  parseRejected = true,
  headers,
  credentials
) =>
  baseRequest(
    'GET',
    fulfilled,
    rejected,
    url,
    null,
    parseFulfilled,
    parseRejected,
    headers,
    credentials
  );

export const postRequest = (
  fulfilled,
  rejected,
  url,
  payload,
  parseFulfilled = true,
  parseRejected = true,
  headers,
  credentials,
  isJSON = true
) =>
  baseRequest(
    'POST',
    fulfilled,
    rejected,
    url,
    payload,
    parseFulfilled,
    parseRejected,
    headers,
    credentials,
    isJSON
  );

export const putRequest = (
  fulfilled,
  rejected,
  url,
  payload,
  jsonPayload = true,
  headers = HEADERS,
  credentials = CREDENTIALS
) =>
  fetch(url, {
    method: 'PUT',
    headers,
    credentials,
    body: (jsonPayload && payload && JSON.stringify(payload)) || payload
  })
    .then(checkStatus)
    .then(response =>
      response.text().then(text => (text ? JSON.parse(text) : {}))
    )
    .then(response => {
      fulfilled(response);
    })
    .catch(error => handleRejected(error, rejected));

export const deleteRequest = (fulfilled, rejected, url) =>
  fetch(url, {
    method: 'DELETE',
    headers: HEADERS,
    credentials: CREDENTIALS
  })
    .then(checkStatus)
    .then(response =>
      response.text().then(text => (text ? JSON.parse(text) : {}))
    )
    .then(response => {
      fulfilled(response);
    })
    .catch(error => handleRejected(error, rejected));
