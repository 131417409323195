import mealType from '@src/utils/lookups/mealType';

export const CATEGORY_BREAKFAST = 'breakfast';
export const CATEGORY_LUNCH = 'lunch';
export const CATEGORY_SNACK = 'snack';
export const CATEGORY_DINNER = 'dinner';
export const CATEGORY_SUPPER = 'supper';
export const CATEGORY_ACTIVITY = 'motion';

export const getCategory = (
  selectedMealTypeTab,
  selectedFoodExerciseTab,
  product
) => {
  if (selectedFoodExerciseTab === 1) return CATEGORY_ACTIVITY;
  return mealType.getMealTypeNameForIndex(product, selectedMealTypeTab);
};
