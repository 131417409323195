export default {
  LOG_EXERCISE_FORM: 'LOG_EXERCISE_FORM',
  LOG_FOOD_FORM: 'LOG_FOOD_FORM',
  LOG_RECIPE_INGREDIENT_FORM: 'LOG_RECIPE_INGREDIENT_FORM',
  QUICK_LOG_FORM: 'QUICK_LOG_FORM',
  LOG_WEIGHT_FORM: 'LOG_WEIGHT_FORM',
  LOG_WAIST_FORM: 'LOG_WAIST_FORM',
  NOTES_FORM: 'NOTES_FORM',
  PROFILE_CONSENT: 'PROFILE_CONSENT',
  CREATE_RECIPE_FORM: 'CREATE_RECIPE_FORM',
  PLUS_MINUS_MIN: 0,
  PLUS_MINUS_MAX: 99,
  PLUS_MINUS_STEP_ONE: 1,
  PLUS_MINUS_STEP_HALF: 0.5
};
