let isBodyOverflowing = false;
let scrollbarWidth = 0;
const SCROLLBAR_MEASURER_CLASS = 'modal-scrollbar-measure';

const getScrollbarWidth = () => {
  const scrollDiv = document.createElement('div');
  scrollDiv.className = SCROLLBAR_MEASURER_CLASS;
  document.body.appendChild(scrollDiv);
  const width = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return width;
};

export const checkScrollbar = () => {
  const fullWindowWidth = window.innerWidth;
  isBodyOverflowing = document.body.clientWidth < fullWindowWidth;
  scrollbarWidth = getScrollbarWidth();
};

export const setScrollbar = () => {
  if (isBodyOverflowing) {
    document.body.style.paddingRight = `${scrollbarWidth}px`;
  }
};

export const resetScrollbar = () => {
  document.body.style.paddingRight = null;
};
