/* eslint-disable no-underscore-dangle */
import Bowser from 'bowser';
import Tracker from '@spt-tracking/pulse-sdk';
import dependencies, { keys } from '@src/utils/helpers/dependencies';
import { parseUserAgent, isPrerender } from '@src/utils/helpers/userAgent';

const pulseConfig = () => dependencies.get(keys.PULSE_CONFIG);
const productId = () => dependencies.get(keys.PRODUCT_ID);
const isEnabled = () => dependencies.get(keys.TRACKING_ENABLED);
const version = () => dependencies.get(keys.VERSION);
const PULSE_EVENT_TYPE_VIEW = 'viewEvent';
const PULSE_EVENT_TYPE_ENGAGEMENT = 'engagementEvent';
const ACCESS_LEVEL_FREE = 'Free';
const ACCESS_LEVEL_PAID = 'Paid';

const pulseAmplitudeTransformVersion = 2;

function getDevice() {
  const { platform } = parseUserAgent(window.navigator.userAgent);
  return {
    platform,
    browser: Bowser.getParser(window.navigator.userAgent).getBrowser().name
  };
}

function getProvider() {
  const originalProductName = productId();
  const productName =
    originalProductName === 'viktklubb' ? 'wellobe' : originalProductName;
  const { productType, productVersion } = parseUserAgent(
    window.navigator.userAgent
  );
  return {
    '@id': `sdrn:schibsted:client:${pulseConfig().clientId}`,
    trackingVersion: pulseAmplitudeTransformVersion,
    techStack: pulseConfig().techStack,
    product: productName,
    productType,
    appVersion: productVersion || version(),
    realm: pulseConfig().realm
  };
}

const originParamsFromLocationSearch = () => {
  const originParams = {};
  const urlParams = new URLSearchParams(document.location.search);
  urlParams.forEach((value, key) => {
    switch (key) {
      case 'content_id':
        originParams.id = value;
        break;
      case 'type':
        originParams.type = value;
        break;
      case 'utm_channel':
      case 'utm_medium':
        originParams.channel = value;
        break;
      case 'utm_source':
        originParams.source = value;
        break;
      case 'utm_campaign':
        originParams.campaign = value;
        break;
      case 'utm_term':
        originParams.term = value;
        break;
      case 'utm_content':
      case 'refpartner':
        originParams.content = value;
        break;
      case 'utm_url':
        originParams.url = value;
        break;
      default:
    }
  });
  return originParams;
};

// eventCanBypassTrackingBlocking - event info, trigger the event without respecting tracking blocking
const initTrackingQueue = trackingState => {
  let queue = [];

  const flushQueue = () => {
    const { forceQueue, healthDataTrackingDisabled } = trackingState;
    if (forceQueue) return;
    const canFlushHealthDataTracking = healthDataTrackingDisabled === false;
    if (canFlushHealthDataTracking) {
      queue.forEach(el => el.f());
      queue = [];
    } else {
      queue.forEach(el => el.eventCanBypassTrackingBlocking && el.f());
      queue = queue.filter(el => el.eventCanBypassTrackingBlocking === false);
    }
  };

  return (fn, context, params, eventCanBypassTrackingBlocking = false) => {
    const wrappedFn = () => {
      fn.apply(context, params);
    };
    queue.push({ eventCanBypassTrackingBlocking, f: wrappedFn });
    flushQueue();
  };
};

const initGetTracker = () => {
  let tracker = null;
  return () => {
    if (!tracker) {
      tracker = new Tracker(
        pulseConfig().clientId,
        {
          useBeacon: true,
          xandrEnabled: false,
          requireAdvertisingOptIn: true
        },
        {
          schema:
            'http://schema.schibsted.com/events/tracker-event.json/229.json',
          deployTag: `${productId()}_${version()}`
        }
      );

      tracker.update({
        provider: getProvider(),
        device: getDevice()
      });
    }
    return tracker;
  };
};

const trackingState = {
  configure(hdcpiStatus, isLoggedIn, cookieConsents) {
    this.forceQueueTracking(cookieConsents);

    if (isLoggedIn === undefined || (isLoggedIn && hdcpiStatus === undefined)) {
      this.queueHealthDataTracking();
      return;
    }
    if (isLoggedIn && hdcpiStatus === 'DECLINED') {
      this.disableHealthDataTracking();
      return;
    }

    this.enableHealthDataTracking();
  },

  purgeQueue() {
    const queuePurger = {
      purge: () => {}
    };
    track(queuePurger.purge, queuePurger, [], true);
  },
  enableHealthDataTracking() {
    this.healthDataTrackingDisabled = false;
    this.purgeQueue();
  },
  disableHealthDataTracking() {
    this.healthDataTrackingDisabled = true;
  },
  queueHealthDataTracking() {
    this.healthDataTrackingDisabled = undefined;
  },
  forceQueueTracking(cookieConsents) {
    const shouldForceQueue = cookieConsents === undefined;
    this.forceQueue = shouldForceQueue;
    if (!shouldForceQueue) {
      getTracker().setConsents(cookieConsents);
      this.purgeQueue();
    }
  }
};

const track = initTrackingQueue(trackingState);
export const getTracker = initGetTracker();

const initSetUser = () => {
  let actor = {};
  return ({ schibstedAccountId, age, gender, programType }) => {
    actor = {
      id: schibstedAccountId || actor.id,
      realm: pulseConfig().realm,
      age: age || actor.age,
      gender: gender || actor.gender,
      program: programType || actor.program,
      subscriptionName: pulseConfig().techStack
    };
    getTracker().update({
      actor
    });
  };
};

const setUser = initSetUser();

const clearUser = () => {
  getTracker().update({
    actor: { id: undefined }
  });
};

const formattedSdrn = sdrn => sdrn.replace(/(\s+|>|-)/g, '_').toLowerCase();

// TODO Replace it with engagement event
async function trackEvent(
  event,
  pulseType,
  eventCanBypassTrackingBlocking = false
) {
  if (!isEnabled() || isPrerender) return;
  const tracker = getTracker();

  const isViewEvent = pulseType === PULSE_EVENT_TYPE_VIEW;
  const eventType = isViewEvent ? 'View' : event.type;
  const eventDescription = isViewEvent
    ? `View Screen ${event.objectName}`
    : event.description ||
      `${event.type} ${event.objectElementType || event.objectType}`;

  const sdrn = `sdrn:${pulseConfig().clientId}:${event.objectType}:${
    event.objectName
  }`;

  const eventObject = {
    '@type': isViewEvent ? 'Screen' : event.objectType,
    '@id': formattedSdrn(sdrn),
    name: event.objectName,
    category: event.objectCategory,
    accessLevel: event.objectAccessLevel,
    elementType: event.objectElementType,
    page: event.objectPage,
    ship_custom: event.shipCustom
  };

  const eventOrigin = {
    '@type': event.originType,
    '@id': event.originId,
    name: event.originName,
    source: event.originSource,
    term: event.originTerm,
    url: event.originUrl,
    campaign: event.originCampaign,
    content: event.originContent,
    channel: event.originChannel
  };

  const eventPayload = {
    type: eventType,
    description: eventDescription,
    intent: event.intent,
    object: eventObject,
    origin: eventOrigin,
    experiments: event.experiments
  };

  if (isViewEvent) tracker.newPageView();
  track(
    tracker.track,
    tracker,
    [pulseType, eventPayload],
    eventCanBypassTrackingBlocking
  );
}

async function trackArticleViewEvent(
  {
    articleId,
    articleTitle,
    articleTags,
    articleCategory,
    originType,
    originId,
    originName,
    originSource,
    originTerm,
    originUrl,
    originCampaign,
    originContent,
    originChannel,
    shipCustom,
    isPaid,
    experiments,
    extras
  },
  eventCanBypassTrackingBlocking = false
) {
  if (!isEnabled() || isPrerender) return;
  const tracker = getTracker();
  tracker.update({
    device: getDevice()
  });
  const accessLevelString = isPaid ? ACCESS_LEVEL_PAID : ACCESS_LEVEL_FREE;
  const eventObject = {
    '@type': 'Article',
    '@id': `sdrn:${pulseConfig().clientId}:article:${articleId}`,
    contentId: articleId,
    name: articleTitle,
    accessLevel: accessLevelString,
    accessLevelName: accessLevelString.toLowerCase(),
    tags: articleTags,
    category: articleCategory,
    ship_custom: shipCustom
  };

  const eventOrigin = {
    '@type': originType,
    '@id': originId,
    name: originName,
    source: originSource,
    term: originTerm,
    url: originUrl,
    campaign: originCampaign,
    content: originContent,
    channel: originChannel,
    ...originParamsFromLocationSearch()
  };

  const eventPayload = {
    type: 'View',
    description: 'View Page Article',
    object: eventObject,
    origin: eventOrigin,
    experiments,
    extras
  };

  track(
    tracker.trackPageView,
    tracker,
    [eventPayload],
    eventCanBypassTrackingBlocking
  );
}

async function trackPageViewEvent(
  {
    objectName,
    objectCategory,
    userIntent,
    isPaid,
    originName,
    originType,
    shipCustom,
    experiments
  },
  eventCanBypassTrackingBlocking = false
) {
  if (!isEnabled() || isPrerender) return;

  const accessLevelString = isPaid ? ACCESS_LEVEL_PAID : ACCESS_LEVEL_FREE;
  const sdrn = `sdrn:${pulseConfig().clientId}:page:${objectName}`;
  const eventObject = {
    '@type': 'Page',
    '@id': formattedSdrn(sdrn),
    name: objectName,
    category: objectCategory,
    accessLevel: accessLevelString,
    accessLevelName: accessLevelString.toLowerCase(),
    ship_custom: shipCustom
  };

  const eventOrigin = {
    '@type': originType,
    name: originName,
    ...originParamsFromLocationSearch()
  };

  const eventPayload = {
    type: 'View',
    description: `View Page ${objectName}`,
    intent: userIntent,
    object: eventObject,
    origin: eventOrigin,
    experiments
  };

  const tracker = getTracker();
  track(
    tracker.trackPageView,
    tracker,
    [eventPayload],
    eventCanBypassTrackingBlocking
  );
}

async function trackContentViewEvent(
  {
    objectName,
    objectCategory,
    originUrl,
    userIntent,
    isPaid,
    originName,
    originType,
    shipCustom,
    experiments
  },
  eventCanBypassTrackingBlocking = false
) {
  if (!isEnabled() || isPrerender) return;

  const accessLevelString = isPaid ? ACCESS_LEVEL_PAID : ACCESS_LEVEL_FREE;
  const sdrn = `sdrn:${pulseConfig().clientId}:content:${objectName}`;
  const eventObject = {
    '@type': 'Content',
    '@id': formattedSdrn(sdrn),
    name: objectName,
    category: objectCategory,
    accessLevel: accessLevelString,
    accessLevelName: accessLevelString.toLowerCase(),
    ship_custom: shipCustom
  };

  const eventOrigin = {
    '@type': originType,
    name: originName,
    url: originUrl,
    ...originParamsFromLocationSearch()
  };

  const eventPayload = {
    type: 'View',
    description: `View Content ${objectName}`,
    intent: userIntent,
    object: eventObject,
    origin: eventOrigin,
    experiments
  };

  const tracker = getTracker();
  track(
    tracker.trackPageView,
    tracker,
    [eventPayload],
    eventCanBypassTrackingBlocking
  );
}

async function trackFrontpageViewEvent() {
  if (!isEnabled() || isPrerender) return;

  const eventObject = {
    '@type': 'Frontpage',
    '@id': `sdrn:${pulseConfig().clientId}:frontpage:sale`,
    name: `Sale page`
  };

  const eventPayload = {
    type: 'View',
    description: `View Page Sale`,
    object: eventObject,
    origin: originParamsFromLocationSearch()
  };

  const tracker = getTracker();
  const eventCanBypassTrackingBlocking = true;
  track(
    tracker.trackPageView,
    tracker,
    [eventPayload],
    eventCanBypassTrackingBlocking
  );
}

async function trackEngagementEvent(
  event,
  eventCanBypassTrackingBlocking = false
) {
  trackEvent(
    event,
    PULSE_EVENT_TYPE_ENGAGEMENT,
    eventCanBypassTrackingBlocking
  );
}

async function addPageLeaveTracking(
  { articleElement, objectName },
  eventCanBypassTrackingBlocking = false
) {
  if (!isEnabled() || isPrerender) return;

  const tracker = getTracker();

  const eventPayload = {
    object: {
      description: `Leave Page ${objectName}`
    }
  };

  track(
    tracker.addPageLeaveTracking,
    tracker,
    [window.document.body, articleElement, () => eventPayload],
    eventCanBypassTrackingBlocking
  );
}

async function trackActivePageLeave(
  { objectName, eventName },
  eventCanBypassTrackingBlocking = false
) {
  if (!isEnabled() || isPrerender) return;

  const tracker = getTracker();

  // TODO: Remove this when pulse-sdk is updated
  // (if they solve overwriting page view values on page leave)
  const eventTemp = tracker.getBookmarkedEvent('page-view') || {};
  eventTemp.description = `Leave Page ${objectName}`;
  tracker.bookmark.set('page-view', eventTemp);

  track(
    tracker.trackActivePageLeave,
    tracker,
    [undefined, eventName],
    eventCanBypassTrackingBlocking
  );
}

export {
  trackPageViewEvent,
  trackFrontpageViewEvent,
  trackArticleViewEvent,
  trackContentViewEvent,
  trackEngagementEvent,
  addPageLeaveTracking,
  trackActivePageLeave,
  setUser,
  clearUser,
  trackingState
};
